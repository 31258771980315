<template>
  <b-row>
    <b-col
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          v-slot="{ invalid }"
          tag="form"
        >
          <b-row>

            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Offer Type"
                rules="required"
              >
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Offer Type"
                >
                  <b-form-radio
                    v-model="couponForm.type"
                    :aria-describedby="ariaDescribedby"
                    name="type"
                    value="P"
                  >
                    percentage
                  </b-form-radio>
                  <b-form-radio
                    v-model="couponForm.type"
                    :aria-describedby="ariaDescribedby"
                    name="type"
                    value="F"
                  >
                    Amount
                  </b-form-radio>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="couponForm.type==='P'"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                rules="required"
                name="offer"
              >
                <b-form-group
                  label="Amount (in %)"
                  label-for="QL"
                >
                  <b-form-input
                    id="QL"
                    v-model.number="couponForm.amount"
                    trim
                    type="number"
                    placeholder="Amount (in %)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="couponForm.type==='F'"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                rules="required"
                name="offer"
              >
                <b-form-group
                  label="Amount (in Numbers)"
                  label-for="QL"
                >
                  <b-form-input
                    id="QL"
                    v-model.number="couponForm.amount"
                    trim
                    type="number"
                    placeholder="Amount (in Numbers)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Quantity"
                rules="required"
              >
                <b-form-group
                  label="Quantity"
                  label-for="Quantity"
                >
                  <b-form-input
                    id="Quantity"
                    v-model.number="couponForm.quantity"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="Quantity"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Min Order"
                rules="required"
              >
                <b-form-group
                  label="Min Order"
                  label-for="Min"
                >
                  <b-form-input
                    id="Min"
                    v-model.number="couponForm.min"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="Min Order"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Max Discount"
                rules="required"
              >
                <b-form-group
                  label="Max Discount"
                  label-for="Max"
                >
                  <b-form-input
                    id="Max"
                    v-model.number="couponForm.max"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="Max"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Expired at"
                rules="required"
              >
                <b-form-group
                  label="Expired at"
                >
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="couponForm.expire"
                    :state="getValidationState(validationContext)"
                    class="mb-1"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group
                label="Multi use code"
                label-for="Active"
              >
                <b-form-checkbox
                  id="Active"
                  v-model="couponForm.user_count_user"
                  unchecked-value="0"
                  value="1"
                  class="custom-control-secondary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="couponForm.user_count_user==1"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Max Usage For User"
                rules="required"
              >
                <b-form-group
                  label="Max Usage For User"
                  label-for="Max Usage For User"
                >
                  <b-form-input
                    id="Max Usage For User"
                    v-model.number="couponForm.user_limit"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="Max Usage For User"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="errorMsg"
              cols="12"
            >
              <p class="text-danger">
                {{ errorMsg }}
              </p>
            </b-col>
            <!-- Server Errors -->
            <b-col
              v-if="Object.values(errors).length > 0"
              cols="12"
            >
              <p
                v-for="(error, key, index) in errors"
                :key="index"
                class="text-danger small"
              >
                {{ error.toString() }}
              </p>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-if="!loader"
                variant="primary"
                class="mr-1"
                :disabled="invalid || Object.values(errors).length > 0"
                @click.prevent="addCoupon()"
              >
                Save
              </b-button>
              <b-button
                v-if="loader"
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, email } from '@validations'
import axios from 'axios'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToggleButton from 'vue-js-toggle-button'
import { ref } from '@vue/composition-api'
import handleFormData from '@/global-compositions/handleFormData'
// import store from '@/store'

Vue.use(ToggleButton)

export default {
  data() {
    return {
      required,
      email,
      merchants: [],
      categories: [],
      test: '',
      value: '',
      errorMsg: '',
      loader: false,
      validationErrors: {},
      merchant_id: '',
      is_active: 0,
      errors: {},
    }
  },

  setup() {
    const couponForm = ref({
      user_count_user: 0,
      user_limit: 1,
    })
    const {
      getValidationState,
    } = formValidation()
    const { formData, setFormData } = handleFormData()

    return {
      getValidationState,
      couponForm,

      formData,
      setFormData,
    }
  },
  mounted() {
    this.showCoupon()
  },
  methods: {

    showCoupon() {
      if (this.$route.params.id) {
        axios.get(`promoCode/${this.$route.params.id}`).then(res => {
          this.couponForm = res.data.data.coupon
        })
      } else {
        return false
      }
      return true
    },
    addCoupon() {
      if (this.$route.params.id) {
        this.loader = true

        this.couponForm.expired_at = this.couponForm.original_expire_at
        this.setFormData(this.couponForm)
        this.formData.append('_method', 'Post')
        axios.post(`promoCode/${this.$route.params.id}`, this.formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'promoCodes' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        this.setFormData(this.couponForm)
        axios.post('promoCode', this.formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'promoCodes' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
.order-type label.btn.btn-primary.active {
    background-color: #0074c7 !important;
}
</style>
